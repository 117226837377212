import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Form} from "@kof/react-commons";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import useTitleFactory from "common/useTitleFactory";
import useSalutationOptions from "user/useSalutationOptions";
import {useCountryOptions} from "common/useCountryOptions";
import {Page} from "pages/Page";
import "./UserAdminPage.scss";

export default function PageAdminPage() {
    const {id} = useParams();
    const requester = useRequester()
    const translate = useTranslator()
    const navigate = useNavigate()
    const salutationOptions = useSalutationOptions()
    const countryOptions = useCountryOptions()
    const titleFactory = useTitleFactory()
    const [conferences, setConferences] = useState([])
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        salutation: null,
        title: '',
        institutionCompany: '',
        street1: '',
        street2: '',
        city: '',
        zip: '',
        country: '',
        email: '',
        memberType: '',
        role: '',
        participations: [],
        additionalInfo: '',
        active: false,
    })

    useEffect(() => {
        requester.request('GET', `rest/conference`, null, response => {
            setConferences(response)
            if (id) {
                const dataQuery = new URLSearchParams({
                    batchItems: JSON.stringify([
                        {method: 'GET', 'uri': `/api/rest/user/${id}`},
                        {method: 'GET', 'uri': `/api/rest/participation?userId=${id}`},
                    ]),
                }).toString()
                requester.request('GET', `batch?${dataQuery}`, null, response => {
                    const responseUser = response[0]
                    const responseUserParticipations = response[1]
                    setFormValues({
                        firstName: responseUser.firstName,
                        lastName: responseUser.lastName,
                        salutation: responseUser.salutation,
                        title: responseUser.title || '',
                        institutionCompany: responseUser.institutionCompany || '',
                        street1: responseUser.street1,
                        street2: responseUser.street2 || '',
                        city: responseUser.city,
                        zip: responseUser.zip,
                        country: responseUser.country,
                        email: responseUser.email,
                        memberType: responseUser.memberType || '',
                        role: responseUser.roles.length ? responseUser.roles[0] : '',
                        participations: responseUserParticipations.map(participation => participation.conference.id),
                        additionalInfo: responseUser.additionalInfo || '',
                        active: responseUser.active,
                    })
                })
            }
        })
    }, [])

    const memberTypeOptions = [
        {value: 'individual', label: translate('users.memberType.individual')},
        {value: 'corporate', label: translate('users.memberType.corporate')},
        {value: 'corporateMainContact', label: translate('users.memberType.corporateMainContact')},
    ]

    const roleOptions = [
        {value: 'admin', label: translate('users.role.admin')},
        {value: 'chairman', label: translate('users.role.chairman')},
        {value: 'reviewer', label: translate('users.role.reviewer')},
    ]

    const conferenceOptions = conferences.map(conference => {
        return {name: conference.id, label: conference.name}
    })

    const createFormElements = () => {
        const elements = []
        elements.push({label: translate('users.firstName'), name: 'firstName', type: 'text', isRequired: true})
        elements.push({label: translate('users.lastName'), name: 'lastName', type: 'text', isRequired: true})
        elements.push({
            label: translate('users.salutation'),
            name: 'salutation',
            type: 'dropdown',
            options: {items: salutationOptions}
        })
        elements.push({type: 'text', label: translate('users.title'), name: 'title'})
        elements.push({
            type: 'text',
            label: translate('users.institutionCompany'),
            name: 'institutionCompany',
            isRequired: (['corporate', 'corporateMainContact'].includes(formValues.memberType))
        })
        elements.push({type: 'text', label: translate('users.street1'), name: 'street1', isRequired: true})
        elements.push({type: 'text', label: translate('users.street2'), name: 'street2'})
        elements.push({type: 'text', label: translate('users.city'), name: 'city', isRequired: true})
        elements.push({type: 'text', label: translate('users.zip'), name: 'zip', isRequired: true})
        elements.push({
            type: 'dropdown',
            label: translate('users.country'),
            name: 'country',
            isRequired: true,
            options: {items: countryOptions}
        })
        elements.push({label: translate('users.email'), name: 'email', type: 'text', isRequired: true})
        elements.push({
            label: translate('users.memberType'),
            name: 'memberType',
            type: 'dropdown',
            options: {items: memberTypeOptions}
        })
        elements.push({
            label: translate('users.role'),
            name: 'role',
            type: 'dropdown',
            options: {items: roleOptions}
        })
        elements.push({
            label: translate('users.participations'),
            name: 'participations',
            type: 'checkboxes',
            options: {items: conferenceOptions}
        })
        elements.push({label: translate('users.additionalInfo'), name: 'additionalInfo', type: 'text'})
        elements.push({label: translate('users.active'), name: 'active', type: 'checkbox'})
        return elements
    }

    const createSubmissionData = (formData) => {
        const data = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            salutation: formData.salutation,
            title: formData.title,
            institutionCompany: formData.institutionCompany,
            street1: formData.street1,
            street2: formData.street2,
            city: formData.city,
            zip: formData.zip,
            country: formData.country,
            email: formData.email,
            memberType: formData.memberType,
            roles: [formData.role],
            additionalInfo: formData.additionalInfo,
            active: formData.active,
        }
        return data
    }

    return <Page title={titleFactory.createCRUDTitle('users.user', id)} className={'user'}>
        <Form values={formValues} onChange={(newValues) => setFormValues(newValues)}
              elements={createFormElements()}
              submit={{
                  label: translate('form.submit'),
                  onSubmit: () => {
                      const data = createSubmissionData(formValues)
                      const submitMethod = id ? 'PATCH' : 'POST'
                      const submitUrl = id ? `rest/user/${id}` : `rest/user`
                      requester.request(submitMethod, submitUrl, data, response => {
                          const userId = id ? id : response
                          const participationsData = {
                              userId: userId,
                              conferenceIds: formValues.participations,
                          }
                          requester.request('POST', `rpc/save-participations`, participationsData, response => {
                              navigate('/admin/users')
                          })
                      })
                  }
              }}
        />
    </Page>
}