import {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {ConferenceImages} from "./ConferenceImages";
import {useRequester} from "common/useRequester";

function ConferenceImagesPageContent(props) {
    const requester = useRequester()
    const [images, setImages] = useState([])

    useEffect(() => {
        requester.request('GET', `rest/conference-image?conferenceId=${props.conferenceId}`, null, response => {
            const images = response.map(image => ({id: image.id, data: image.file.data, name: image.file.name}))
            setImages(images)
        })
    }, [])

    return <ConferenceImages images={images} showNames={false}/>
}

ConferenceImagesPageContent.propTypes = {
    conferenceId: PropTypes.string.isRequired,
}
export {ConferenceImagesPageContent}