import {useState} from "react";
import PropTypes from 'prop-types';
import {Button, downloader, Icon, Modal, sorter} from "@kof/react-commons";
import useTranslator from "common/useTranslator";
import "./ConferenceImages.scss";

function ConferenceImages(props) {
    const translate = useTranslator()
    const [removingItem, setRemovingItem] = useState(null)

    return <div id={'conference-images'}>

        {sorter.sortObjectsByProperty(props.images, 'name').map(image => {
            const src = downloader.createImageSrc(image.data)
            const open = () => {
                const newWindow = window.open();
                newWindow.document.write(`<img src=${src} />`);
            }
            return <div className={'item'} key={image.id}>
                <div className={'image'}>
                    {props.onSubmitRemove &&
                        <Icon type={'remove-trash'} className={'remove'} onClick={() => setRemovingItem(image)}/>
                    }
                    <img src={src} onClick={open}/>
                </div>
                {(props.showNames !== false) && <p>{image.name}</p>}
            </div>
        })}

        {props.onSubmitRemove && <>
            <Modal isOpen={Boolean(removingItem)} onClose={() => setRemovingItem(null)}>
                <p>{translate('crud.remove.are-you-sure', {name: removingItem?.name})}</p>
                <Button onClick={() => {
                    props.onSubmitRemove(removingItem)
                    setRemovingItem(null)
                }}>{translate('crud.remove')}</Button>
            </Modal>
        </>}

    </div>
}

ConferenceImages.propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        data: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    onSubmitRemove: PropTypes.func,
    showNames: PropTypes.bool,
}
export {ConferenceImages}