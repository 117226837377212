import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useLayout} from "contexts/LayoutContext";
import {useRequester} from "common/useRequester";
import {Page} from "pages/Page";
import {PresentedPapers} from "components/PresentedPapers";
import {ConferenceImages} from "components/ConferenceImages/ConferenceImages";
import {ConferenceImagesPageContent} from "components/ConferenceImages/ConferenceImagesPageContent";
import {finder} from "@kof/react-commons";

export default function CustomPage() {
    const requester = useRequester()
    const location = useLocation()
    const {pages} = useLayout()
    const navigate = useNavigate()
    const [fetchedPage, setFetchedPage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const fetchPage = (id) => {
        setIsLoading(true)
        requester.request('GET', `rest/page/${id}`, null,
            response => {
                setFetchedPage(response)
                setIsLoading(false)
            },
            error => {
                if (error.response?.status === 401) {
                    navigate('/account/login')
                }
            }
        )
    }

    const showPageNotFound = () => {
        navigate('/')
    }

    useEffect(() => {
        if (!pages.length) { // happens on initial load, and full reload.
            return
        }
        const page = finder.findRecursive('path', decodeURIComponent(location.pathname), pages)
        if (page) {
            fetchPage(page.id)
            return
        }
        showPageNotFound()
    }, [location, pages])

    const showPresentedPapers = (conferenceId) => {
        return <>
            <h3>{fetchedPage.name}</h3>
            <PresentedPapers conferenceId={conferenceId}/>
        </>
    }

    const showConferenceImages = (conferenceId) => {
        return <>
            <h3>{fetchedPage.name}</h3>
            <ConferenceImagesPageContent conferenceId={conferenceId}/>
        </>
    }

    const showContent = () => {
        const snippetPattern = /{"componentName": "(.+)", "conferenceId": "(.+)"}/g
        const snippetMatches = Array.from(fetchedPage.content.matchAll(snippetPattern))
        if (!snippetMatches.length) {
            // Content should be safe. During write, the script tags are removed.
            return <div dangerouslySetInnerHTML={{__html: fetchedPage.content}}/>
        }
        const componentName = snippetMatches[0][1]
        const conferenceId = snippetMatches[0][2]
        switch (componentName) {
            case 'PresentedPapers':
                return showPresentedPapers(conferenceId)
            case 'ConferenceImages':
                return showConferenceImages(conferenceId)
        }
    }

    if (!fetchedPage || isLoading) {
        return
    }

    return <Page title={fetchedPage.name} customPage>
        {showContent()}
    </Page>
}