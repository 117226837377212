import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Table} from "@kof/react-commons";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import {Page} from "pages/Page";

export default function ConferencesAdminPage() {
    const requester = useRequester()
    const translate = useTranslator()
    const navigate = useNavigate()
    const [items, setItems] = useState([])

    const loadItems = () => {
        requester.request('GET', 'rest/conference', null, response => {
            setItems(response)
        })
    }

    useEffect(() => {
        loadItems()
    }, [])

    return <Page title={translate('conferences')}>
        <Table
            columns={[
                {label: translate('name'), name: 'name'},
                {label: translate('date'), name: 'date'},
            ]}
            items={items}
            actions={{
                onClickAdd: () => navigate('/admin/conference'),
                onClickItem: (item) => navigate(`/admin/conference/${item.id}`),
                onSubmitRemoval: (item) => requester.request('DELETE', `rest/conference/${item.id}`, null, response => {
                    loadItems()
                }),
            }}
            translate={translate}
            labelProperty={'name'}
        />
    </Page>
}