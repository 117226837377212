import {useNavigate} from "react-router-dom";
import {useLayout} from "contexts/LayoutContext";
import {Tree} from "@kof/react-commons";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import {Page} from "pages/Page";
import {useEffect, useState} from "react";

export default function PagesAdminPage() {
    const requester = useRequester()
    const translate = useTranslator()
    const navigate = useNavigate()
    const {loadLayoutData} = useLayout()
    const [allPages, setAllPages] = useState([])
    const treeItems = allPages.map(page => {
        return {id: page.id, parentId: page.parentId, label: page.name}
    })

    const loadAllPages = () => {
        requester.request('GET', `rest/page?context=administration`, null, response => {
            setAllPages(response)
        })
    }

    useEffect(() => {
        loadAllPages()
    }, [])

    const move = (item, newSequence) => {
        requester.request('PATCH', `rest/page/${item.id}`, {sequence: newSequence}, response => {
            loadLayoutData()
            loadAllPages()
        })
    }
    return <Page title={translate('pages')}>
        <Tree items={treeItems}
              actions={{
                  onClickAdd: () => navigate('/admin/page'),
                  onClickChange: (item) => navigate(`/admin/page/${item.id}`),
                  onClickMoveUp: (item, currentSequence) => move(item, currentSequence - 1),
                  onClickMoveDown: (item, currentSequence) => move(item, currentSequence + 1),
                  onSubmitRemoval: (item) => requester.request('DELETE', `rest/page/${item.id}`, null, response => {
                      loadLayoutData()
                      loadAllPages()
                  }),
              }}
              translate={translate}
        />
    </Page>
}