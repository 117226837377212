import PropTypes from 'prop-types';
import {useEffect} from "react";

function Page(props) {

    useEffect(() => {
        if (props.title) {
            document.title = `${props.title} - CIRET`
        }
    }, [])

    return <div id={'page'} className={props.className}>
        {!props.customPage && props.title && <h3>{props.title}</h3>}
        {props.children}
    </div>
}

Page.propTypes = {
    title: PropTypes.string,
    customPage: PropTypes.bool,
    className: PropTypes.string,
}
export {Page}