import {useEffect, useState} from "react";
import {Button, Link, merger, Modal, Table} from "@kof/react-commons";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import {Page} from "pages/Page";
import zeroFiller from "common/ZeroFiller";
import "./PapersActivationPage.scss";

export default function PapersActivationPage() {
    const requester = useRequester()
    const translate = useTranslator()
    const [papers, setPapers] = useState([])
    const [chosenPaper, setChosenPaper] = useState(null)
    const [allPaperRatings, setAllPaperRatings] = useState([])
    // todo, use rating summary which is now also calculated in api
    const filterRatingsByPaper = (paperRatings, paper) => paperRatings.filter(paperRating => paperRating.paperId === paper.id)
    const filterRatingsByRating = (paperRatings, rating) => paperRatings.filter(paperRating => paperRating.rating === rating)
    const chosenPaperRatings = chosenPaper ? filterRatingsByPaper(allPaperRatings, chosenPaper) : []
    const chosenPaperARatings = filterRatingsByRating(chosenPaperRatings, 'A')
    const chosenPaperBRatings = filterRatingsByRating(chosenPaperRatings, 'B')
    const apiUrl = process.env.REACT_APP_API_URL

    const tableItems = papers.map(paper => {
        const singlePaperRatings = filterRatingsByPaper(allPaperRatings, paper)
        const ARatings = filterRatingsByRating(singlePaperRatings, 'A')
        const BRatings = filterRatingsByRating(singlePaperRatings, 'B')
        return {
            id: paper.id,
            'papers.number': paper.number,
            title: paper.title,
            'papers.user': paper.user?.name,
            'conferences.conference': paper.conference.name,
            'chairman.ratings.ratingSummary': <>A ({ARatings.length}), B ({BRatings.length})</>,
            active: paper.active ? translate('yes') : translate('no'),
        }
    })

    const loadItems = () => {
        const dataQuery = new URLSearchParams({
            batchItems: JSON.stringify([
                {method: 'GET', 'uri': '/api/rest/paper'},
                {method: 'GET', 'uri': '/api/rest/paper-rating'},
            ]),
        }).toString()
        requester.request('GET', `batch?${dataQuery}`, null, response => {
            const responsePapers = response[0]
            const responsePaperRatings = response[1]
            setPapers(responsePapers)
            setAllPaperRatings(responsePaperRatings)
        })
    }

    useEffect(() => {
        loadItems()
    }, [])

    return <Page title={translate('chairman.papers.activation')} className={'papers-activation-page'}>
        <Table
            columns={[
                {label: translate('papers.number'), name: 'papers.number', class: 'number', format: value => value ? zeroFiller.fill(value) : null},
                {label: translate('title'), name: 'title'},
                {label: translate('papers.user'), name: 'papers.user'},
                {label: translate('conferences.conference'), name: 'conferences.conference'},
                {label: translate('chairman.ratings.ratingSummary'), name: 'chairman.ratings.ratingSummary'},
                {label: translate('active'), name: 'active'},
            ]}
            items={tableItems}
            translate={translate}
            actions={{
                onClickItem: (item) => {
                    requester.request('GET', `rest/paper/${item.id}`, null, response => {
                        setChosenPaper(merger.mergeObjects([response, {id: item.id}]))
                    })
                },
            }}
        />
        {chosenPaper && <Modal isOpen={true} onClose={() => setChosenPaper(null)}>
            <p>
                <Link target={`${apiUrl}/api/rest/paper/${chosenPaper.id}`} newPage>
                    {chosenPaper.file.name}
                </Link>
            </p>
            <p>
                <strong>{translate('chairman.ratings.summary')}</strong>
            </p>
            <p>
                A {translate('chairman.ratings')}: {chosenPaperARatings.length}<br/>
                B {translate('chairman.ratings')}: {chosenPaperBRatings.length}
            </p>
            {Boolean(chosenPaperRatings.length) && <>
                <p>
                    <strong>{translate('chairman.ratings.details')}</strong>
                </p>
                {chosenPaperRatings.map(paperRating => <p key={paperRating.raterName}>
                    {translate('chairman.ratings.rater')}: {paperRating.raterName}<br/>
                    {translate('chairman.ratings.rating')}: {paperRating.rating}<br/>
                    {paperRating.comment && <>{translate('reviewer.rating.comment')}: {paperRating.comment}</>}
                </p>)}
            </>}

            <Button onClick={() => {
                const endpoint = chosenPaper.active ?
                    'rpc/deactivate-paper' :
                    'rpc/activate-paper'
                requester.request('POST', endpoint, {id: chosenPaper.id}, response => {
                    setChosenPaper(null)
                    loadItems()
                })
            }}>
                {chosenPaper.active ?
                    translate('chairman.ratings.deactivate') :
                    translate('chairman.ratings.activate')}
            </Button>
        </Modal>}
    </Page>
}