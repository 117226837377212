import {useEffect, useState} from "react";
import {Table, Modal, Form, Link} from "@kof/react-commons";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import {Page} from "pages/Page";
import zeroFiller from "common/ZeroFiller";
import "./PapersRatingPage.scss";

export default function PapersRatingPage() {
    const requester = useRequester()
    const translate = useTranslator()
    const [papers, setPapers] = useState([])
    const [myRatings, setMyRatings] = useState([])
    const [chosenPaper, setChosenPaper] = useState(null)
    const createInitialFormValues = () => ({
        paperLink: null,
        rating: null,
        comment: '',
    })
    const [formValues, setFormValues] = useState(createInitialFormValues())
    const ratingOptions = [
        {value: 'A', label: 'A'},
        {value: 'B', label: 'B'},
    ]
    const getMyRating = (paper) => myRatings.find(rating => rating.paperId === paper.id)
    const apiUrl = process.env.REACT_APP_API_URL

    const tableItems = papers.map(paper => {
        const myRating = getMyRating(paper)
        return {
            id: paper.id,
            'papers.number': paper.number,
            title: paper.title,
            'papers.user': paper.user?.name,
            'conferences.conference': paper.conference.name,
            'reviewer.rating.myRating': myRating?.rating,
        }
    })

    const loadItems = () => {
        const dataQuery = new URLSearchParams({
            batchItems: JSON.stringify([
                {method: 'GET', 'uri': '/api/rest/paper'},
                {method: 'GET', 'uri': '/api/rpc/get-my-paper-ratings'},
            ]),
        }).toString()
        requester.request('GET', `batch?${dataQuery}`, null, response => {
            const responsePapers = response[0]
            const responseMyPaperRatings = response[1]
            setPapers(responsePapers)
            setMyRatings(responseMyPaperRatings)
        })
    }

    useEffect(() => {
        loadItems()
    }, [])

    return <Page title={translate('reviewer.papers.rating')} className={'rating'}>
        <Table
            columns={[
                {label: translate('papers.number'), name: 'papers.number', class: 'number', format: value => value ? zeroFiller.fill(value) : null},
                {label: translate('title'), name: 'title'},
                {label: translate('papers.user'), name: 'papers.user'},
                {label: translate('conferences.conference'), name: 'conferences.conference'},
                {label: translate('reviewer.rating.myRating'), name: 'reviewer.rating.myRating', class: 'my-rating'},
            ]}
            items={tableItems}
            actions={{
                onClickItem: (changingItem) => {
                    const tableItem = tableItems.find(tableItem => tableItem.id === changingItem.id)
                    const myRating = getMyRating(tableItem)
                    requester.request('GET', `rest/paper/${tableItem.id}`, null, response => {
                        const paper = response
                        setChosenPaper(Object.assign({id: tableItem.id}, paper))
                        setFormValues({
                            paperLink: <Link target={`${apiUrl}/api/rest/paper/${tableItem.id}`} newPage>
                                {paper.file.name}
                            </Link>,
                            rating: myRating?.rating,
                            comment: myRating?.comment || '',
                        })
                    })
                },
            }}
            translate={translate}
        />
        <Modal isOpen={Boolean(chosenPaper)} onClose={() => setChosenPaper(null)}>
            <Form values={formValues} onChange={(newValues) => setFormValues(newValues)}
                  elements={[
                      {label: translate('papers.paper'), name: 'paperLink', type: 'component', component: formValues.paperLink},
                      {
                          label: translate('reviewer.rating.myRating'),
                          name: 'rating',
                          type: 'dropdown',
                          options: {items: ratingOptions},
                          isRequired: true,
                      },
                      {label: translate('reviewer.rating.comment'), name: 'comment', type: 'textarea'},
                  ]}
                  submit={{
                      onSubmit: () => {
                          const data = {
                              paperId: chosenPaper.id,
                              rating: formValues.rating,
                              comment: formValues.comment,
                          }
                          requester.request('POST', `rpc/rate-paper`, data, response => {
                              setChosenPaper(null)
                              setFormValues(createInitialFormValues())
                              loadItems()
                          })
                      }
                  }}
            />
        </Modal>
    </Page>
}