import {Link} from "@kof/react-commons";
import useTranslator from "common/useTranslator";

export default function AwardFormDescription() {
    const translate = useTranslator()

    const link = <Link target={window.location.origin + '/about-us/isaac-kerstenetzky-award'}>
        {translate('papers.awardFormDescription.link')}
    </Link>

    return <p>
        {translate('papers.awardFormDescription.pre')} {link} ({translate('papers.awardFormDescription.inBraces')})
    </p>
}