import {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Table} from "@kof/react-commons";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import zeroFiller from "common/ZeroFiller";

function PresentedPapers(props) {
    const requester = useRequester()
    const translate = useTranslator()
    const [items, setItems] = useState([])
    const apiUrl = process.env.REACT_APP_API_URL

    const tableItems = items.map(item => {
        return {
            id: item.id,
            'papers.number': item.number,
            title: item.title,
            "papers.user": item.user.name,
        }
    })

    useEffect(() => {
        requester.request('GET', `rpc/presented-papers?conferenceId=${props.conferenceId}`, null, papersResponse => {
            setItems(papersResponse)
        })
    }, [])

    return <>
        <Table
            columns={[
                {label: translate('papers.number'), name: 'papers.number', class: 'number', format: value => zeroFiller.fill(value)},
                {label: translate('title'), name: 'title'},
                {label: translate('papers.user'), name: 'papers.user'},
            ]}
            items={tableItems}
            translate={translate}
            actions={{
                onClickItem: (item) => {
                    window.open(`${apiUrl}/api/rest/paper/${item.id}`, '_blank')
                }
            }}
        />
    </>
}

PresentedPapers.propTypes = {
    conferenceId: PropTypes.string.isRequired,
}
export {PresentedPapers}