import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Table} from "@kof/react-commons";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import {Page} from "pages/Page";
import "./FilesAdminPage.scss";

export default function FilesAdminPage() {
    const requester = useRequester()
    const translate = useTranslator()
    const navigate = useNavigate()
    const [items, setItems] = useState([])

    const tableItems = items.map(item => ({
        id: item.id,
        name: item.file.name,
        'access.restriction': item.accessRestriction,
    }))

    const loadItems = () => {
        requester.request('GET', `rest/file`, null, response => {
            setItems(response)
        })
    }

    useEffect(() => {
        loadItems()
    }, [])

    return <Page title={translate('files')} className={'files-admin-page'}>
        <Table
            columns={[
                {label: translate('name'), name: 'name'},
                {label: translate('access.restriction'), name: 'access.restriction'},
            ]}
            items={tableItems}
            actions={{
                onClickAdd: () => navigate('/admin/file'),
                onClickItem: (item) => navigate(`/admin/file/${item.id}`),
                onSubmitRemoval: (item) => requester.request('DELETE', `rest/file/${item.id}`, null, response => {
                    loadItems()
                }),
            }}
            translate={translate}
            labelProperty={'name'}
        />
    </Page>
}