import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Form, Link} from "@kof/react-commons"
import {useLayout} from "contexts/LayoutContext";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import {Page} from "pages/Page";

export default function LoginPage() {
    const navigate = useNavigate()
    const requester = useRequester()
    const translate = useTranslator()
    const {user, setUser} = useLayout()
    const [formValues, setFormValues] = useState({
        email: '',
        password: '',
    })

    const formElements = [
        {label: translate('users.email'), name: 'email', type: "text"},
        {label: translate('users.password'), name: 'password', type: "password"},
    ]

    if (user) {
        navigate('/')
    }

    return <Page title={translate('login')}>
        <Form values={formValues} onChange={(newValues) => setFormValues(newValues)}
              elements={formElements}
              submit={{
                  label: translate('form.submit'),
                  onSubmit: () => {
                      const data = {
                          username: formValues.email,
                          password: formValues.password,
                      };
                      requester.request('POST', 'login', data, response => {
                          location.href = "/";
                      })
                  }
              }}
        />
        <p>
            <Link target={() => navigate('/account/registration')}>
                {translate('registration')}
            </Link>
        </p>
        <p>
            <Link target={() => navigate('/account/forgot-password')}>
                {translate('account.forgot-password')}
            </Link>
        </p>
    </Page>
}