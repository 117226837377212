import {createContext, useContext, useEffect, useState} from 'react';
import {useRequester} from "common/useRequester";
import {cloner, treeFactory} from "@kof/react-commons";

const LayoutContext = createContext({
    user: null,
    setUser: () => {
    },
    profileWasFetched: false,
    pages: [],
    loadLayoutData: () => {
    },
});

function LayoutProvider({children}) {
    const requester = useRequester()
    const [user, setUser] = useState(null)
    const [profileWasFetched, setProfileWasFetched] = useState(false)
    const [pages, setPages] = useState([]);

    const enrichPagesWithPaths = (treePages) => {
        const newTreePages = cloner.clone(treePages)
        const convertToPrettyUrl = (string) => string.replaceAll(' ', '-').toLowerCase()
        const addPaths = (treePages, parentPath = '') => {
            treePages.forEach((page, index) => {
                const isHomePage = (parentPath === '' && index === 0)
                const currentPath = isHomePage ? '/' : convertToPrettyUrl(`${parentPath}/${page.name}`)
                page.path = currentPath
                if (page.children) {
                    addPaths(page.children, currentPath)
                }
            })
        }
        addPaths(newTreePages)
        return newTreePages
    }

    const loadLayoutData = () => {
        const dataQuery = new URLSearchParams({
            batchItems: JSON.stringify([
                {method: 'GET', 'uri': `/api/rpc/get-my-profile`},
                {method: 'GET', 'uri': `/api/rest/page`},
            ]),
        }).toString()
        requester.request('GET', `batch?${dataQuery}`, null, response => {
            const responseProfile = response[0]
            const responsePages = response[1]
            const treePages = treeFactory.create(responsePages)
            const enrichedTreePages = enrichPagesWithPaths(treePages)

            setUser(responseProfile)
            setProfileWasFetched(true)
            setPages(enrichedTreePages)
        })
    }

    useEffect(() => {
        loadLayoutData()
    }, [])

    return <LayoutContext.Provider value={{user, setUser, profileWasFetched, pages, loadLayoutData}}>
        {children}
    </LayoutContext.Provider>;
}

function useLayout() {
    return useContext(LayoutContext);
}

export {LayoutContext, LayoutProvider, useLayout}