class ZeroFiller {

    /**
     * todo, was also needed in backend for filename saving, so logic is now double. zero filling is not anymore only for display.
     * maybe refactor paper api to store formatted number (maybe rename to identifier (string)), so that it is already zerofilled on read.
     */
    fill(number) {
        switch (number.toString().length) {
            case 1:
                return `000${number}`
            case 2:
                return `00${number}`
            case 3:
                return `0${number}`
            default:
                return number.toString()
        }
    }

}

const zeroFiller = new ZeroFiller()
export default zeroFiller