import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {BsFileEarmarkZip} from "react-icons/bs";
import {downloader, Table, requester as commonRequester} from "@kof/react-commons";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import {Page} from "pages/Page";
import zeroFiller from "common/ZeroFiller";
import "./PapersAdminPage.scss";

export default function PapersAdminPage() {
    const requester = useRequester()
    const translate = useTranslator()
    const navigate = useNavigate()
    const [items, setItems] = useState([])
    const [filters, setFilters] = useState(null)

    const tableItems = items.map(item => ({
        id: item.id,
        'papers.number': item.number,
        title: item.title,
        'papers.user': item.user?.name,
        'conferences.conference': item.conference.name,
    }))

    const createFiltersQuery = () => {
        if (!filters) {
            return ''
        }
        const apiFilters = {}
        if (filters['conferences.conference']) {
            apiFilters.conferenceName = filters['conferences.conference']
        }
        return commonRequester.objectToQuery(apiFilters)
    }

    const loadItems = () => {
        const filtersQuery = createFiltersQuery()
        requester.request('GET', `rest/paper?${filtersQuery}`, null, response => {
            setItems(response)
        })
    }

    useEffect(() => {
        loadItems()
    }, [filters])

    return <Page title={translate('papers')} className={'papers-admin-page'}>
        <Table
            columns={[
                {
                    label: translate('papers.number'),
                    name: 'papers.number',
                    class: 'number',
                    format: value => value ? zeroFiller.fill(value) : null
                },
                {label: translate('title'), name: 'title'},
                {label: translate('papers.user'), name: 'papers.user'},
                {label: translate('conferences.conference'), name: 'conferences.conference'},
            ]}
            items={tableItems}
            actions={{
                onClickAdd: () => navigate('/admin/paper'),
                onClickItem: (item) => navigate(`/admin/paper/${item.id}`),
                onSubmitRemoval: (item) => requester.request('DELETE', `rest/paper/${item.id}`, null, response => {
                    loadItems()
                }),
                onClickDownloadList: () => {
                    const filtersQuery = createFiltersQuery()
                    requester.request('GET', `rest/paper?${filtersQuery}`, null, response => {
                        downloader.download(response, 'papers.csv')
                    }, null, {'Content-Type': 'text/csv'})
                },
                extraTop: [
                    <BsFileEarmarkZip className={'zip-download'} onClick={() => {
                        const filtersQuery = createFiltersQuery()
                        window.open(`${process.env.REACT_APP_API_URL}/api/rest/paper?content-type=zip&${filtersQuery}`, '_blank');
                    }}/>,
                ],
            }}
            filters={{
                columns: ['conferences.conference'],
                onChangeDelayed: (filters) => {
                    setFilters(filters)
                },
            }}
            translate={translate}
            labelProperty={'title'}
        />
    </Page>
}