import {NavLink} from "react-router-dom";
import {Link} from "@kof/react-commons";
import {useLayout} from "contexts/LayoutContext";
import useTranslator from "common/useTranslator";
import {useRequester} from "common/useRequester";
import "./Navigation.scss";

const isPageActive = (page) => {
    const currentPath = window.location.pathname
    const isHomePage = !page.parentId && (page.sequence === 1)
    if (isHomePage) {
        return currentPath === '/'
    }
    if (currentPath === page.path) {
        return true
    }
    const currentPathsLastSlashIndex = currentPath.lastIndexOf('/');
    const currentParentPath = currentPath.substring(0, currentPathsLastSlashIndex);
    const isAnyChildActive = currentParentPath.startsWith(page.path)
    if (isAnyChildActive) {
        return true
    }
    return false
}

function Navigation() {
    const translate = useTranslator()
    const requester = useRequester()
    const {user, pages} = useLayout()

    const logout = () => {
        requester.request('POST', 'logout', null, response => {
            window.location.href = "/";
        })
    }

    return <div id={"navigation"}>
        {pages.map(page => {
            return <NavLink to={page.path} key={page.id}
                            className={() => isPageActive(page) ? 'active' : null}>
                {page.name}
            </NavLink>
        })}
        {!user && <NavLink to={`/account/login`}>{translate('login')}</NavLink>}
        {user && <Link target={logout}>
            {translate('logout')}
        </Link>}
    </div>
}

export {Navigation, isPageActive}