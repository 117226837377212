import "./Header.scss";

export default function Header() {
    return <div id={"header"}>
        <a href={"/"}>
            <img src={'/logo.png'}/>
        </a>
        <h1>CIRET</h1>
        <h2>
            Centre for International Research<br/>
            on Economic Tendency Surveys
        </h2>
    </div>
}