import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Form, merger, treeFactory} from "@kof/react-commons";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import useTitleFactory from "common/useTitleFactory";
import {Page} from "pages/Page";
import JelFormDescription from "components/JelFormDescription";
import AwardFormDescription from "components/AwardFormDescription";

export default function ProfilePaperPage() {
    const {id} = useParams()
    const navigate = useNavigate()
    const requester = useRequester()
    const translate = useTranslator()
    const titleFactory = useTitleFactory()
    const [topics, setTopics] = useState([])
    const [nextConference, setNextConference] = useState(null)
    const [nextConferenceWasChecked, setNextConferenceWasChecked] = useState(false)
    const [formValues, setFormValues] = useState({
        conference: '',
        title: '',
        subTitle: '',
        coAuthors: '',
        abstract: '',
        files: [],
        topic: null,
        keywords: '',
        jelClassification: '',
        novelty: '',
        awardApplication: '',
        submissionAgreement: false,
    })
    const topicsTree = treeFactory.create(topics)
    const topicsTreeList = treeFactory.createListFromTree(topicsTree, 'title', '-')
    const topicOptions = topicsTreeList.map(topic => ({value: topic.id, label: topic.title}))

    useEffect(() => {
        const dataQuery = new URLSearchParams({
            batchItems: JSON.stringify([
                {method: 'GET', 'uri': '/api/rest/topic'},
                {method: 'GET', 'uri': '/api/rest/conference'},
            ]),
        }).toString()
        requester.request('GET', `batch?${dataQuery}`, null, response => {
            const responseTopics = response[0]
            const responseConferences = response[1]
            setTopics(responseTopics)

            if (!id) {
                const nextConference = responseConferences.find(conference => {
                    return new Date(conference.date) > new Date()
                })
                if (nextConference) {
                    const newFormValues = merger.mergeObjects([formValues, {
                        conference: <span>{nextConference.name}</span>
                    }])
                    setNextConference(nextConference)
                    setFormValues(newFormValues)
                }
                setNextConferenceWasChecked(true)
                return
            }
            if (id) {
                requester.request('GET', `rest/paper/${id}`, null, response => {
                    let populatingTopicId = null
                    if (response.topicId) {
                        const availableTopic = responseTopics.find(topic => topic.id === response.topicId)
                        populatingTopicId = availableTopic ? response.topicId : null
                    }
                    const newFormValues = merger.mergeObjects([formValues, {
                        conference: <span>{response.conference.name}</span>,
                        title: response.title,
                        subTitle: response.subTitle,
                        coAuthors: response.coAuthors,
                        abstract: response.abstract,
                        files: [response.file],
                        topic: populatingTopicId,
                        keywords: response.keywords,
                        jelClassification: response.jelClassification,
                        novelty: response.novelty,
                        awardApplication: response.awardApplication,
                    }])
                    setFormValues(newFormValues)
                })
            }
        })
    }, [])

    const renderForm = () => {
        return <Form values={formValues} onChange={(newValues) => setFormValues(newValues)}
                     elements={[
                         {label: translate('conferences.conference'), name: 'conference', type: 'component', component: formValues.conference},
                         {label: translate('title'), name: 'title', type: 'text', isRequired: true},
                         {label: translate('papers.subTitle'), name: 'subTitle', type: 'text'},
                         {label: translate('papers.coAuthors'), name: 'coAuthors', type: 'text'},
                         {label: translate('papers.abstract'), name: 'abstract', type: 'textarea', isRequired: true},
                         {
                             label: translate('file'),
                             name: 'files',
                             type: 'filePicker',
                             isRequired: true,
                             options: {fileEndings: ['.pdf']}
                         },
                         {
                             label: translate('topics.topic'),
                             name: 'topic',
                             type: 'dropdown',
                             isRequired: true,
                             options: {items: topicOptions}
                         },
                         {label: translate('papers.keywords'), name: 'keywords', type: 'textarea'},
                         {
                             label: translate('papers.jelClassification'),
                             name: 'jelClassification',
                             type: 'text',
                             options: {description: <JelFormDescription/>}
                         },
                         {label: translate('papers.novelty'), name: 'novelty', type: 'textarea'},
                         {
                             label: translate('papers.awardApplication'),
                             name: 'awardApplication',
                             type: 'checkbox',
                             options: {description: <AwardFormDescription/>}
                         },
                         {
                             label: translate('papers.submissionAgreement'),
                             name: 'submissionAgreement',
                             type: 'checkbox',
                             isRequired: true,
                             options: {description: translate('papers.submissionAgreement.description')}
                         },
                     ]}
                     submit={{
                         label: translate('form.submit'),
                         onSubmit: () => {
                             const data = {
                                 title: formValues.title,
                                 subTitle: formValues.subTitle,
                                 coAuthors: formValues.coAuthors,
                                 abstract: formValues.abstract,
                                 file: formValues.files[0],
                                 topicId: formValues.topic,
                                 keywords: formValues.keywords,
                                 jelClassification: formValues.jelClassification,
                                 novelty: formValues.novelty,
                                 awardApplication: Boolean(formValues.awardApplication),
                                 submissionAgreement: Boolean(formValues.submissionAgreement),
                             }
                             if (!id) {
                                 data.conferenceId = nextConference.id
                             }
                             const submitMethod = id ? 'PATCH' : 'POST'
                             const submitUrl = id ? `rest/paper/${id}` : `rest/paper`
                             requester.request(submitMethod, submitUrl, data, response => {
                                 navigate('/profile/papers')
                             })
                         }
                     }}
                     translate={translate}
        />
    }
    return <Page title={titleFactory.createCRUDTitle('papers.paper', id)}>
        {!id && nextConferenceWasChecked && !nextConference && <p>{translate('profile.papers.noNextConference')}</p>}
        {!id && nextConferenceWasChecked && nextConference && renderForm()}
        {id && renderForm()}
    </Page>
}