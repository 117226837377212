import Cookies from 'js-cookie';
import {merger, useRequest} from "@kof/react-commons";
import useTranslator from "./useTranslator";

const containsFatalError = (body) => { // out-of-memory fatal error was not catchable in API
    return (typeof body === 'string' && body.includes('Fatal error'))
}

export function useRequester() {
    const {requester, setRequestError} = useRequest()
    const translate = useTranslator()
    const apiUrl = process.env.REACT_APP_API_URL
    const CSRFToken = Cookies.get('CSRF-Token')
    const initialHeaders = CSRFToken ? {'X-CSRF-Token': CSRFToken} : {}

    const request = (method, relativeUrl, data, onSuccess, onError, additionalHeaders) => {
        const headers = merger.mergeObjects([initialHeaders, additionalHeaders])
        const request = {method, url: `${apiUrl}/api/${relativeUrl}`, body: data}
        requester.request(
            request,
            (body) => {
                if (containsFatalError(body)) { // todo https://gitlab.ethz.ch/KOF/php-api/-/issues/29
                    throw new Error();
                }
                onSuccess(body)
            },
            (error) => {
                if (onError) {
                    onError(error)
                    return
                }
                // todo, handle exception responses more generically
                if (error.response?.status === 401) {
                    setRequestError(translate('request.error.unauthorized'))
                    return
                }
                const exception = error.response?.body.exception
                if (exception?.name === 'RequiredDataException') {
                    setRequestError(translate('request.error.missing-data.key', {requiredKey: translate(exception.message.invalidKey)}))
                    return
                }
                if (exception?.name === 'NonNumericDataException') {
                    setRequestError(translate('request.error.non-numeric-data.key', {requiredKey: translate(exception.message.invalidKey)}))
                    return
                }
                if (exception?.name === 'EmailDataException') {
                    setRequestError(translate('request.error.email-data.key', {requiredKey: translate(exception.message.invalidKey)}))
                    return
                }
                if (exception?.name === 'InvalidPasswordException') {
                    setRequestError(translate(exception.message.translationKey))
                    return
                }
                if (exception?.name === 'EmailAlreadyTakenException') {
                    setRequestError(translate('error.email.already-taken'))
                    return
                }
                setRequestError(translate('request.error'))
            },
            headers,
            {credentials: 'include'},
        )
    }
    return {request};
}
