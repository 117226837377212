import useTranslator from "common/useTranslator";

const useTitleFactory = () => {
    const translate = useTranslator()

    const createCRUDTitle = (entityNameTranslationKey, id) => {
        const entityName = translate(entityNameTranslationKey).toLowerCase()
        const actionTranslationKey = id ? 'crud.update' : 'crud.create'
        return translate(actionTranslationKey, {entityName: entityName})
    }

    return {createCRUDTitle}
}
export default useTitleFactory