import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useLayout} from "contexts/LayoutContext";
import {Form, treeFactory, TextEditor} from "@kof/react-commons";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import useTitleFactory from "common/useTitleFactory";
import {Page} from "pages/Page";

export default function PageAdminPage() {
    const requester = useRequester()
    const translate = useTranslator()
    const navigate = useNavigate()
    const titleFactory = useTitleFactory()
    const {id} = useParams();
    const {pages, loadLayoutData} = useLayout()
    const pagesList = treeFactory.createListFromTree(pages, 'name', '-')
    const pagesOptions = pagesList.map(item => ({value: item.id, label: item.name}))
    const [formValues, setFormValues] = useState({
        parent: null,
        name: '',
        accessRestriction: null,
        visibilityRestriction: null,
        content: '',
    })
    const [showForm, setShowForm] = useState(false) // populating WYSIWYG content async, lets somehow reset the other form values

    useEffect(() => {
        if (!id) {
            setShowForm(true)
        }
        if (id) {
            requester.request('GET', `rest/page/${id}`, null, response => {
                setFormValues({
                    parent: response.parentId || null,
                    name: response.name,
                    accessRestriction: response.accessRestriction,
                    visibilityRestriction: response.visibilityRestriction,
                    content: response.content,
                })
                setShowForm(true)
            })
        }
    }, [])

    const accessRestrictionOptions = [
        {value: 'loggedIn', label: translate('pages.page.accessRestriction.logged-in')},
        {value: 'member', label: translate('pages.page.accessRestriction.member')},
    ]
    const visibilityRestrictionOptions = [
        {value: 'loggedIn', label: translate('pages.page.visibilityRestriction.logged-in')},
    ]
    return <Page title={titleFactory.createCRUDTitle('pages.page', id)}>
        {showForm &&
            <Form values={formValues} onChange={(newValues) => setFormValues(newValues)}
                  elements={[
                      {
                          label: translate('pages.page.parent'),
                          name: 'parent',
                          type: 'dropdown',
                          options: {items: pagesOptions}
                      },
                      {label: translate('pages.page.name'), name: 'name', type: 'text', isRequired: true},
                      {
                          label: translate('pages.page.accessRestriction'),
                          name: 'accessRestriction',
                          type: 'dropdown',
                          options: {items: accessRestrictionOptions}
                      },
                      {
                          label: translate('pages.page.visibilityRestriction'),
                          name: 'visibilityRestriction',
                          type: 'dropdown',
                          options: {items: visibilityRestrictionOptions}
                      },
                      {
                          label: translate('pages.page.content'),
                          type: 'component',
                          name: 'content',
                          isRequired: true,
                          component: <TextEditor
                              content={formValues.content}
                              onChange={(newContent) => {
                                  const newFormValues = Object.assign({}, formValues)
                                  newFormValues.content = newContent
                                  setFormValues(newFormValues)
                              }}
                              headingConfigOptionNames={['paragraph', 'heading3', 'heading4', 'heading5', 'container']}
                          />,
                      },
                  ]}
                  submit={{
                      label: translate('form.submit'),
                      onSubmit: () => {
                          const data = {
                              parentId: formValues.parent,
                              name: formValues.name,
                              accessRestriction: formValues.accessRestriction ? formValues.accessRestriction : null,
                              visibilityRestriction: formValues.visibilityRestriction ? formValues.visibilityRestriction : null,
                              content: formValues.content,
                          }
                          const submitMethod = id ? 'PATCH' : 'POST'
                          const relativeUrl = id ? `rest/page/${id}` : `rest/page`
                          requester.request(submitMethod, relativeUrl, data, response => {
                              loadLayoutData()
                              navigate('/admin/pages')
                          })
                      }
                  }}
            />
        }
    </Page>
}