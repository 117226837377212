import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Form, treeFactory} from "@kof/react-commons";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import useTitleFactory from "common/useTitleFactory";
import {Page} from "pages/Page";
import JelFormDescription from "components/JelFormDescription";
import AwardFormDescription from "components/AwardFormDescription";

export default function PaperAdminPage() {
    const {id} = useParams();
    const requester = useRequester()
    const translate = useTranslator()
    const titleFactory = useTitleFactory()
    const navigate = useNavigate()
    const [users, setUsers] = useState([])
    const [conferences, setConferences] = useState([])
    const [topics, setTopics] = useState([])
    const [formValues, setFormValues] = useState({
        user: null,
        conference: null,
        title: '',
        subTitle: '',
        coAuthors: '',
        abstract: '',
        files: [],
        topic: null,
        keywords: '',
        jelClassification: '',
        novelty: '',
        awardApplication: false,
    })
    const userOptions = users.map(user => ({value: user.id, label: user.name}))
    const conferenceOptions = conferences.map(conference => ({value: conference.id, label: conference.name}))
    const topicsTree = treeFactory.create(topics)
    const topicsTreeList = treeFactory.createListFromTree(topicsTree, 'title', '-')
    const topicOptions = topicsTreeList.map(topic => ({value: topic.id, label: topic.title}))

    useEffect(() => {
        const dataQuery = new URLSearchParams({
            batchItems: JSON.stringify([
                {method: 'GET', 'uri': '/api/rest/user'},
                {method: 'GET', 'uri': '/api/rest/topic'},
                {method: 'GET', 'uri': '/api/rest/conference'},
            ]),
        }).toString()
        requester.request('GET', `batch?${dataQuery}`, null, response => {
            setUsers(response[0])
            setTopics(response[1])
            setConferences(response[2])
        })
        if (id && users && topics && conferences) {
            requester.request('GET', `rest/paper/${id}`, null, response => {
                setFormValues({
                    user: response.user?.id,
                    conference: response.conference.id,
                    title: response.title,
                    subTitle: response.subTitle || '',
                    coAuthors: response.coAuthors || '',
                    abstract: response.abstract,
                    files: [response.file],
                    topic: response.topicId,
                    keywords: response.keywords,
                    jelClassification: response.jelClassification,
                    novelty: response.novelty || '',
                    awardApplication: response.awardApplication,
                })
            })
        }
    }, [])

    return <Page title={titleFactory.createCRUDTitle('papers.paper', id)}>
        <Form values={formValues} onChange={(newValues) => setFormValues(newValues)}
              elements={[
                  {
                      label: translate('papers.user'),
                      name: 'user',
                      type: 'dropdown',
                      isRequired: true,
                      options: {items: userOptions}
                  },
                  {
                      label: translate('conferences.conference'),
                      name: 'conference',
                      type: 'dropdown',
                      isRequired: true,
                      options: {items: conferenceOptions}
                  },
                  {label: translate('title'), name: 'title', type: 'text', isRequired: true},
                  {label: translate('papers.subTitle'), name: 'subTitle', type: 'text'},
                  {label: translate('papers.coAuthors'), name: 'coAuthors', type: 'text'},
                  {label: translate('papers.abstract'), name: 'abstract', type: 'textarea', isRequired: true},
                  {
                      label: translate('file'),
                      name: 'files',
                      type: 'filePicker',
                      isRequired: true,
                      options: {fileEndings: ['.pdf']},
                  },
                  {
                      label: translate('topics.topic'),
                      name: 'topic',
                      type: 'dropdown',
                      isRequired: true,
                      options: {items: topicOptions},
                  },
                  {label: translate('papers.keywords'), name: 'keywords', type: 'textarea'},
                  {
                      label: translate('papers.jelClassification'),
                      name: 'jelClassification',
                      type: 'text',
                      isRequired: true,
                      options: {description: <JelFormDescription/>}
                  },
                  {label: translate('papers.novelty'), name: 'novelty', type: 'textarea'},
                  {
                      label: translate('papers.awardApplication'),
                      name: 'awardApplication',
                      type: 'checkbox',
                      options: {description: <AwardFormDescription/>},
                  },
              ]}
              submit={{
                  label: translate('form.submit'),
                  onSubmit: () => {
                      const data = {
                          userId: formValues.user,
                          conferenceId: formValues.conference,
                          title: formValues.title,
                          subTitle: formValues.subTitle,
                          coAuthors: formValues.coAuthors,
                          abstract: formValues.abstract,
                          file: formValues.files[0],
                          topicId: formValues.topic,
                          keywords: formValues.keywords,
                          jelClassification: formValues.jelClassification,
                          novelty: formValues.novelty,
                          awardApplication: formValues.awardApplication,
                      }
                      const submitMethod = id ? 'PATCH' : 'POST'
                      const submitUrl = id ? `rest/paper/${id}` : `rest/paper`
                      requester.request(submitMethod, submitUrl, data, response => {
                          navigate('/admin/papers')
                      })
                  }
              }}
              translate={translate}
        />
    </Page>
}