import {Link, NavLink} from "react-router-dom";
import {finder, Tree, treeFactory} from "@kof/react-commons";
import {useLayout} from "contexts/LayoutContext";
import useTranslator from "common/useTranslator";
import {isPageActive} from "components/Navigation/Navigation";
import useMemberTypeTranslations from "common/useMemberTypeTranslations";
import "./Sidebar.scss";

export default function Sidebar() {
    const {user, pages} = useLayout()
    const translate = useTranslator()
    const memberTypeTranslations = useMemberTypeTranslations()
    const page = finder.findRecursive('path', decodeURIComponent(window.location.pathname), pages)
    const pagesList = treeFactory.createListFromTree(pages, 'name')
    const treeItems = pagesList.map(page => {
        return {
            id: page.id,
            parentId: page.parentId,
            label: <NavLink to={page.path}>{page.name}</NavLink>,
            class: isPageActive(page) ? 'active' : null,
        }
    })

    const getRootPageId = (id) => {
        const page = pagesList.find(page => page.id === id);
        const isMostTopPage = !page.parentId
        if (isMostTopPage) {
            return page.id;
        }
        return getRootPageId(page.parentId);
    }

    return <div id={"sidebar"}>
        <div id={"side-navigation"}>
            {Boolean(pages.length) && page && <Tree
                items={treeItems}
                rootItemId={getRootPageId(page.id)}
            />}
        </div>

        {user && <>
            <hr className={'divider'}/>
            <p>
                {translate('profile.logged-in-as')}:<br/>
                {user.name}
            </p>
            {user.memberType && <p>
                {translate('profile.membership')}:<br/>
                {memberTypeTranslations[user.memberType]}
            </p>}
            <p>
                <Link to={'/profile'}>{translate('profile')}</Link><br/>
                <Link to={'/profile/papers'}>{translate('profile.my-papers')}</Link>
            </p>
            {['admin'].some(role => user.roles.includes(role)) && <>
                <hr className={'divider'}/>
                <p>
                    <strong>{translate('admin')}</strong><br/>
                    <Link to={`/admin/pages`}>{translate('pages')}</Link><br/>
                    <Link to={`/admin/users`}>{translate('users')}</Link><br/>
                    <Link to={`/admin/conferences`}>{translate('conferences')}</Link><br/>
                    <Link to={`/admin/papers`}>{translate('papers')}</Link><br/>
                    <Link to={`/admin/topics`}>{translate('topics')}</Link><br/>
                    <Link to={`/admin/files`}>{translate('files')}</Link>
                </p>
            </>}
            {['admin', 'reviewer'].some(role => user.roles.includes(role)) && <>
                <hr className={'divider'}/>
                <p>
                    <strong>{translate('reviewer')}</strong><br/>
                    <Link to={`/reviewer/papers`}>{translate('reviewer.papers.rating')}</Link>
                </p>
            </>}
            {['admin', 'chairman'].some(role => user.roles.includes(role)) && <>
                <hr className={'divider'}/>
                <p>
                    <strong>{translate('chairman')}</strong><br/>
                    <Link to={`/chairman/papers`}>{translate('chairman.papers.activation')}</Link>
                </p>
            </>}
        </>}
    </div>
}