import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';
import {useLayout} from "contexts/LayoutContext";

function PrivateRoute(props) {
    const {profileWasFetched, user} = useLayout()
    const navigate = useNavigate()
    const isLoggedIn = Boolean(user)
    const hasPermission = isLoggedIn && (
        !props.role ||
        user.roles.includes('admin') ||
        (props.role && Boolean(user.roles.includes(props.role)))
    )

    useEffect(() => {
        if (profileWasFetched && !hasPermission) { // for hard reload
            navigate('/')
        }
    })

    return hasPermission && props.page
}

PrivateRoute.propTypes = {
    page: PropTypes.object.isRequired,
    role: PropTypes.string,
}
export {PrivateRoute}