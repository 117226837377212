import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Form} from "@kof/react-commons"
import {useLayout} from "contexts/LayoutContext";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import {Page} from "pages/Page";
import "./ResetPasswordPage.scss";

export default function ResetPasswordPage() {
    const requester = useRequester()
    const translate = useTranslator()
    const {profileWasFetched, user} = useLayout()
    const navigate = useNavigate()
    const [formValues, setFormValues] = useState({
        password: '',
        repeatedPassword: '',
    })

    useEffect(() => {
        if (user) {
            navigate('/')
        }
    })

    if (!profileWasFetched) {
        return
    }
    return <Page title={translate('account.reset-password')} className={'reset-password-page'}>
        <p>{translate('account.password.rule.length')}</p>
        <Form values={formValues} onChange={(newValues) => setFormValues(newValues)}
              elements={[
                  {
                      type: 'password',
                      label: translate('account.reset-password.password.new'),
                      name: 'password',
                      isRequired: true
                  },
                  {
                      type: 'password',
                      label: translate('account.reset-password.password.new-repeat'),
                      name: 'repeatedPassword',
                      isRequired: true
                  },
              ]}
              submit={{
                  label: translate('form.submit'),
                  onSubmit: () => {
                      const url = new URL(window.location.href)
                      const token = url.searchParams.get('token')
                      const data = {
                          token: token,
                          password: formValues.password,
                          repeatedPassword: formValues.repeatedPassword,
                      };
                      requester.request('POST', 'rpc/reset-password', data, response => {
                          navigate('/account/login')
                      })
                  }
              }}
        />
    </Page>
}