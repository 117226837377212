import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Table} from "@kof/react-commons";
import {useLayout} from "contexts/LayoutContext";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import {Page} from "pages/Page";
import zeroFiller from "common/ZeroFiller";

export default function ProfilePapersPage() {
    const navigate = useNavigate()
    const requester = useRequester()
    const translate = useTranslator()
    const {user} = useLayout()
    const [papers, setPapers] = useState([])
    const tableItems = papers.map(item => ({
        id: item.id,
        'papers.number': item.number,
        title: item.title,
        'conferences.conference': item.conference.name,
        active: item.active ? translate('yes') : translate('no'),
    }))
    const loadItems = () => {
        if (user) {
            requester.request('GET', `rest/paper?userId=${user.id}`, null, response => {
                const papers = response
                setPapers(papers)
            })
        }
    }
    useEffect(() => {
        loadItems()
    }, [user])

    return <Page title={translate('profile.my-papers')}>
        <Table
            columns={[
                {label: translate('papers.number'), name: 'papers.number', class: 'number', format: value => zeroFiller.fill(value)},
                {label: translate('title'), name: 'title'},
                {label: translate('conferences.conference'), name: 'conferences.conference'},
                {label: translate('active'), name: 'active'},
            ]}
            items={tableItems}
            actions={{
                onClickAdd: () => navigate('/profile/paper'),
                onClickItem: (item) => navigate(`/profile/paper/${item.id}`),
                onSubmitRemoval: (item) => requester.request('DELETE', `rest/paper/${item.id}`, null, response => {
                    loadItems()
                }),
            }}
            translate={translate}
            labelProperty={'title'}
        />
    </Page>
}