import {useState} from "react";
import {Form} from "@kof/react-commons"
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import {useCountryOptions} from "common/useCountryOptions";
import useSalutationOptions from "user/useSalutationOptions";
import {Page} from "pages/Page";
import "./RegistrationPage.scss";

export default function RegistrationPage() {
    const requester = useRequester()
    const translate = useTranslator()
    const countryOptions = useCountryOptions()
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        salutation: null,
        title: '',
        institutionCompany: '',
        street1: '',
        street2: '',
        city: '',
        zip: '',
        country: '',
        email: '',
        password: '',
    })
    const salutationOptions = useSalutationOptions()

    const renderForm = () => {
        return <Form values={formValues} onChange={(newValues) => setFormValues(newValues)}
                     elements={[
                         {type: 'text', label: translate('users.firstName'), name: 'firstName', isRequired: true},
                         {type: 'text', label: translate('users.lastName'), name: 'lastName', isRequired: true},
                         {
                             label: translate('users.salutation'),
                             name: 'salutation',
                             type: 'dropdown',
                             options: {items: salutationOptions}
                         },
                         {type: 'text', label: translate('users.title'), name: 'title'},
                         {
                             type: 'text',
                             label: translate('users.institutionCompany'),
                             name: 'institutionCompany',
                         },
                         {type: 'text', label: translate('users.street1'), name: 'street1', isRequired: true},
                         {type: 'text', label: translate('users.street2'), name: 'street2'},
                         {type: 'text', label: translate('users.city'), name: 'city', isRequired: true},
                         {type: 'text', label: translate('users.zip'), name: 'zip', isRequired: true},
                         {
                             type: 'dropdown',
                             label: translate('users.country'),
                             name: 'country',
                             isRequired: true,
                             options: {items: countryOptions}
                         },
                         {type: 'text', label: translate('users.email'), name: 'email', isRequired: true},
                         {
                             type: 'password',
                             label: translate('users.password'),
                             name: 'password',
                             isRequired: true,
                             options: {
                                 description: <p>{translate('account.password.rule.length')}</p>
                             }
                         },
                     ]}
                     submit={{
                         label: translate('form.submit'),
                         onSubmit: () => {
                             const data = {
                                 firstName: formValues.firstName,
                                 lastName: formValues.lastName,
                                 salutation: formValues.salutation,
                                 title: formValues.title,
                                 institutionCompany: formValues.institutionCompany,
                                 street1: formValues.street1,
                                 street2: formValues.street2,
                                 city: formValues.city,
                                 zip: formValues.zip,
                                 country: formValues.country,
                                 email: formValues.email,
                                 password: formValues.password,
                             };
                             requester.request('POST', 'rpc/register-user', data, response => {
                                 setIsSubmitted(true)
                             })
                         }
                     }}
        />
    }

    return <Page title={translate('registration')} className={'registration'}>
        {!isSubmitted ? renderForm() : <p>{translate('registration.submitted')}</p>}
    </Page>
}