import useTranslator from "common/useTranslator";

const useSalutationOptions = () => {
    const translate = useTranslator()
    const salutationOptions = [
        {value: 'mr', label: translate('users.salutation.mister')},
        {value: 'ms', label: translate('users.salutation.miss')},
        {value: 'mrs', label: translate('users.salutation.missis')},
    ]
    return salutationOptions
}
export default useSalutationOptions