import useTranslator from "common/useTranslator";

const useMemberTypeTranslations = () => {
    const translate = useTranslator()
    return {
        individual: translate('users.memberType.individual'),
        corporate: translate('users.memberType.corporate'),
        corporateMainContact: translate('users.memberType.corporateMainContact'),
    }
}
export default useMemberTypeTranslations