import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {FilePicker, Form, Tabs} from "@kof/react-commons";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import useTitleFactory from "common/useTitleFactory";
import {ConferenceImages} from "components/ConferenceImages/ConferenceImages";
import {Page} from "pages/Page";
import "./ConferenceAdminPage.scss";

export default function ConferenceAdminPage() {
    const {id} = useParams();
    const requester = useRequester()
    const translate = useTranslator()
    const navigate = useNavigate()
    const titleFactory = useTitleFactory()
    const [formValues, setFormValues] = useState({name: '', date: ''})
    const [images, setImages] = useState([])

    const loadImages = () => {
        requester.request('GET', `rest/conference-image?conferenceId=${id}`, null, response => {
            const images = response.map(image => ({id: image.id, data: image.file.data, name: image.file.name}))
            setImages(images)
        })
    }

    useEffect(() => {
        if (id) {
            requester.request('GET', `rest/conference/${id}`, null, response => {
                setFormValues({name: response.name, date: response.date})
                loadImages()
            })
        }
    }, [])

    const createTabItems = () => {
        const items = []
        items.push({
            label: translate('general'),
            content: <>
                <Form values={formValues} onChange={(newValues) => setFormValues(newValues)}
                      elements={[
                          {label: translate('name'), name: 'name', type: 'text', isRequired: true},
                          {label: translate('date'), name: 'date', type: 'date', isRequired: true},
                      ]}
                      submit={{
                          label: translate('form.submit'),
                          onSubmit: () => {
                              const submitMethod = id ? 'PATCH' : 'POST'
                              const submitUrl = id ? `rest/conference/${id}` : `rest/conference`
                              requester.request(submitMethod, submitUrl, formValues, response => {
                                  navigate('/admin/conferences')
                              })
                          }
                      }}
                />
            </>
        })
        if (id) {
            items.push({
                label: translate('conferences.images'),
                content: <>
                    <FilePicker label={translate('crud.create', {entityName: translate('conferences.images')})}
                                fileEndings={['jpg']}
                                files={[]}
                                multiple
                                onChange={(chosenFiles) => {
                                    const data = {conferenceId: id, items: []}
                                    chosenFiles.forEach(chosenFile => {
                                        data.items.push({name: chosenFile.name, data: chosenFile.data})
                                    })
                                    requester.request('POST', `rpc/add-conference-images`, data, response => {
                                        loadImages()
                                    })
                                }}
                    />
                    <ConferenceImages images={images}
                                      onSubmitRemove={(item) => {
                                          requester.request('DELETE', `rest/conference-image/${item.id}`, null, response => {
                                              loadImages()
                                          })
                                      }}
                    />
                </>
            })
        }
        return items
    }
    return <Page title={titleFactory.createCRUDTitle('conferences.conference', id)}
                 className={'conference-admin-page'}>
        <Tabs items={createTabItems()}/>
    </Page>
}