import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Form} from "@kof/react-commons";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import useTitleFactory from "common/useTitleFactory";
import {Page} from "pages/Page";

export default function FileAdminPage() {
    const {id} = useParams();
    const requester = useRequester()
    const translate = useTranslator()
    const titleFactory = useTitleFactory()
    const navigate = useNavigate()
    const [formValues, setFormValues] = useState({
        accessRestriction: '',
        files: [],
    })

    useEffect(() => {
        if (id) {
            requester.request('GET', `rest/file/${id}`, null, response => {
                setFormValues({
                    accessRestriction: response.accessRestriction,
                    files: [response.file],
                })
            })
        }
    }, [])

    const accessRestrictionOptions = [
        {value: 'loggedIn', label: translate('access.restriction.logged-in')},
    ]

    return <Page title={titleFactory.createCRUDTitle('files.file', id)}>
        <Form values={formValues} onChange={(newValues) => setFormValues(newValues)}
              elements={[
                  {
                      label: translate('files.file'),
                      name: 'files',
                      type: 'filePicker',
                      isRequired: true,
                      options: {fileEndings: ['.pdf']},
                  },
                  {
                      label: translate('access.restriction'),
                      name: 'accessRestriction',
                      type: 'dropdown',
                      options: {items: accessRestrictionOptions}
                  },
              ]}
              submit={{
                  label: translate('form.submit'),
                  onSubmit: () => {
                      const data = {
                          file: formValues.files[0],
                          accessRestriction: formValues.accessRestriction ? formValues.accessRestriction : null,
                      }
                      const submitMethod = id ? 'PATCH' : 'POST'
                      const submitUrl = id ? `rest/file/${id}` : `rest/file`
                      requester.request(submitMethod, submitUrl, data, response => {
                          navigate('/admin/files')
                      })
                  }
              }}
              translate={translate}
        />
    </Page>
}