import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {downloader, Table} from "@kof/react-commons";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import {Page} from "pages/Page";

export default function UsersAdminPage() {
    const requester = useRequester()
    const translate = useTranslator()
    const navigate = useNavigate()
    const [users, setUsers] = useState([])
    const tableItems = users.map(user => ({
        id: user.id,
        'users.name': `${user.firstName} ${user.lastName}`,
        'users.email': user.email,
        'users.active': user.active ? translate('yes') : translate('no'),
    }))
    const loadUsers = () => {
        requester.request('GET', 'rest/user', null, response => {
            setUsers(response)
        })
    }

    useEffect(() => {
        loadUsers()
    }, [])

    return <Page title={translate('users')}>
        <Table
            columns={[
                {label: translate('users.name'), name: 'users.name'},
                {label: translate('users.email'), name: 'users.email'},
                {label: translate('users.active'), name: 'users.active'},
            ]}
            items={tableItems}
            actions={{
                onClickAdd: () => navigate('/admin/user'),
                onClickItem: (item) => navigate(`/admin/user/${item.id}`),
                onSubmitRemoval: (item) => requester.request('DELETE', `rest/user/${item.id}`, null, response => {
                    loadUsers()
                }),
                onClickDownloadList: () => {
                    requester.request('GET', `rest/user`, null, response => {
                        downloader.download(response, 'users.csv')
                    }, null, {'Content-Type': 'text/csv'})
                }
            }}
            translate={translate}
            labelProperty={'users.name'}
        />
    </Page>
}