import {useEffect} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {useRequest, LoadingOverlay, Message} from "@kof/react-commons";
import {LayoutProvider} from "contexts/LayoutContext";
import useTranslator from "common/useTranslator";
import {Navigation} from "components/Navigation/Navigation";
import Header from "components/Header/Header";
import Sidebar from "components/Sidebar/Sidebar";
import Content from "components/Content/Content";
import "./Layout.scss";

export default function Layout() {
    const {pending, requestError, setRequestError} = useRequest()
    const location = useLocation()
    const translate = useTranslator()

    useEffect(() => {
        setRequestError(null)
    }, [location])

    return <div id={"layout"}>
        <Header/>
        <LayoutProvider>
            <Navigation/>
            <div id={"body"}>
                <Sidebar/>
                <Content>
                    {pending && <LoadingOverlay/>}
                    {requestError && <Message type={"error"}>{translate(requestError)}</Message>}
                    <Outlet/>
                </Content>
            </div>
        </LayoutProvider>
    </div>
}