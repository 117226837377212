import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Tree} from "@kof/react-commons";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import {Page} from "pages/Page";

export default function TopicsAdminPage() {
    const requester = useRequester()
    const translate = useTranslator()
    const navigate = useNavigate()
    const [topics, setTopics] = useState([])
    const treeItems = topics.map(topic => {
        return {id: topic.id, parentId: topic.parentId, label: topic.title}
    })
    const loadItems = () => {
        requester.request('GET', 'rest/topic?context=administration', null, response => {
            setTopics(response)
        })
    }

    useEffect(() => {
        loadItems()
    }, [])

    return <Page title={translate('topics')}>
        <Tree items={treeItems}
              actions={{
                  onClickAdd: () => navigate('/admin/topic'),
                  onClickChange: (item) => navigate(`/admin/topic/${item.id}`),
                  onSubmitRemoval: (item) => requester.request('DELETE', `rest/topic/${item.id}`, null, response => {
                      loadItems()
                  }),
              }}
              translate={translate}
        />
    </Page>
}