import useTranslator from "common/useTranslator";
import {Link} from "@kof/react-commons";

export default function JelFormDescription() {
    const translate = useTranslator()

    return <div>
        <Link target={'https://www.aeaweb.org/jel/guide/jel.php'}>
            {translate('papers.jelFormDescription')}
        </Link>
    </div>
}