import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {RequestProvider} from "@kof/react-commons";
import Layout from "components/Layout/Layout";
import LoginPage from "pages/LoginPage";
import RegistrationPage from "pages/RegistrationPage";
import ResetPasswordPage from "pages/ResetPasswordPage";
import ForgotPasswordPage from "pages/ForgotPasswordPage";
import ActivateAccountPage from "./pages/ActivateAccountPage";
import ProfilePage from "pages/profile/ProfilePage";
import ProfilePaperPage from "pages/profile/ProfilePaperPage";
import ProfilePapersPage from "pages/profile/ProfilePapersPage";
import CustomPage from "pages/CustomPage";
import PagesAdmin from "pages/admin/Pages/PagesAdminPage";
import PageAdminPage from "pages/admin/Pages/PageAdminPage";
import UsersAdminPage from "pages/admin/UsersAdminPage";
import UserAdminPage from "pages/admin/UserAdminPage";
import ConferencesAdminPage from "pages/admin/Conference/ConferencesAdminPage";
import ConferenceAdminPage from "pages/admin/Conference/ConferenceAdminPage";
import PapersAdminPage from "pages/admin/Papers/PapersAdminPage";
import PaperAdminPage from "pages/admin/Papers/PaperAdminPage";
import TopicsAdminPage from "./pages/admin/Topics/TopicsAdminPage";
import TopicAdminPage from "./pages/admin/Topics/TopicAdminPage";
import FilesAdminPage from "./pages/admin/Files/FilesAdminPage";
import FileAdminPage from "./pages/admin/Files/FileAdminPage";
import PapersRatingPage from "./pages/reviewer/PapersRatingPage";
import PapersActivationPage from "./pages/chairman/PapersActivationPage";
import {PrivateRoute} from "./pages/PrivateRoute";
import "@kof/react-commons/src/style/base.scss";
import 'i18n.js';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        children: [
            {index: true, element: <CustomPage/>},
            {
                path: "account",
                children: [
                    {path: "login", element: <LoginPage/>},
                    {path: "registration", element: <RegistrationPage/>},
                    {path: "forgot-password", element: <ForgotPasswordPage/>},
                    {path: "reset-password", element: <ResetPasswordPage/>},
                    {path: "activate", element: <ActivateAccountPage/>},
                ]
            },
            {
                path: "profile",
                children: [
                    {path: "", element: <PrivateRoute page={<ProfilePage/>}/>},
                    {path: "papers", element: <PrivateRoute page={<ProfilePapersPage/>}/>},
                    {path: "paper", element: <PrivateRoute page={<ProfilePaperPage/>}/>},
                    {path: "paper/:id", element: <PrivateRoute page={<ProfilePaperPage/>}/>},
                ]
            },
            {
                path: "admin", children: [
                    {path: "pages", element: <PrivateRoute page={<PagesAdmin/>} role={'admin'}/>},
                    {path: "page", element: <PrivateRoute page={<PageAdminPage/>} role={'admin'}/>},
                    {path: "page/:id", element: <PrivateRoute page={<PageAdminPage/>} role={'admin'}/>},
                    {path: "users", element: <PrivateRoute page={<UsersAdminPage/>} role={'admin'}/>},
                    {path: "user", element: <PrivateRoute page={<UserAdminPage/>} role={'admin'}/>},
                    {path: "user/:id", element: <PrivateRoute page={<UserAdminPage/>} role={'admin'}/>},
                    {path: "conferences", element: <PrivateRoute page={<ConferencesAdminPage/>} role={'admin'}/>},
                    {path: "conference", element: <PrivateRoute page={<ConferenceAdminPage/>} role={'admin'}/>},
                    {path: "conference/:id", element: <PrivateRoute page={<ConferenceAdminPage/>} role={'admin'}/>},
                    {path: "papers", element: <PrivateRoute page={<PapersAdminPage/>} role={'admin'}/>},
                    {path: "paper", element: <PrivateRoute page={<PaperAdminPage/>} role={'admin'}/>},
                    {path: "paper/:id", element: <PrivateRoute page={<PaperAdminPage/>} role={'admin'}/>},
                    {path: "topics", element: <PrivateRoute page={<TopicsAdminPage/>} role={'admin'}/>},
                    {path: "topic", element: <PrivateRoute page={<TopicAdminPage/>} role={'admin'}/>},
                    {path: "topic/:id", element: <PrivateRoute page={<TopicAdminPage/>} role={'admin'}/>},
                    {path: "files", element: <PrivateRoute page={<FilesAdminPage/>} role={'admin'}/>},
                    {path: "file", element: <PrivateRoute page={<FileAdminPage/>} role={'admin'}/>},
                    {path: "file/:id", element: <PrivateRoute page={<FileAdminPage/>} role={'admin'}/>},
                ]
            },
            {
                path: "reviewer", children: [
                    {path: "papers", element: <PrivateRoute page={<PapersRatingPage/>} role={'reviewer'}/>},
                ]
            },
            {
                path: "chairman", children: [
                    {path: "papers", element: <PrivateRoute page={<PapersActivationPage/>} role={'chairman'}/>},
                ]
            },
            {path: "*", element: <CustomPage/>},
        ]
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RequestProvider>
            <RouterProvider router={router}/>
        </RequestProvider>
    </React.StrictMode>
)