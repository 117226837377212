import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Form, treeFactory} from "@kof/react-commons";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import useTitleFactory from "common/useTitleFactory";
import {Page} from "pages/Page";

export default function TopicAdminPage() {
    const requester = useRequester()
    const translate = useTranslator()
    const titleFactory = useTitleFactory()
    const navigate = useNavigate()
    const {id} = useParams();
    const [topics, setTopics] = useState([])
    const [formValues, setFormValues] = useState({
        parent: null,
        title: '',
        visible: true,
    })
    const topicsTree = treeFactory.create(topics)
    const topicsTreeList = treeFactory.createListFromTree(topicsTree, 'title', '-')
    const parentOptions = topicsTreeList.map(item => ({value: item.id, label: item.title}))

    useEffect(() => {
        requester.request('GET', `rest/topic?context=administration`, null, response => {
            setTopics(response)
        })
        if (id) {
            requester.request('GET', `rest/topic/${id}`, null, response => {
                setFormValues({
                    parent: response.parentId,
                    title: response.title,
                    visible: response.visible,
                })
            })
        }
    }, [])

    return <Page title={titleFactory.createCRUDTitle('topics.topic', id)}>
        <Form values={formValues} onChange={(newValues) => setFormValues(newValues)}
              elements={[
                  {label: translate('title'), name: 'title', type: 'text', isRequired: true},
                  {label: translate('parent'), name: 'parent', type: 'dropdown', options: {items: parentOptions}},
                  {label: translate('visible'), name: 'visible', type: 'checkbox'},
              ]}
              submit={{
                  label: translate('form.submit'),
                  onSubmit: () => {
                      const data = {
                          parentId: formValues.parent,
                          title: formValues.title,
                          visible: formValues.visible,
                      }
                      const submitMethod = id ? 'PATCH' : 'POST'
                      const relativeUrl = id ? `rest/topic/${id}` : `rest/topic`
                      requester.request(submitMethod, relativeUrl, data, response => {
                          navigate('/admin/topics')
                      })
                  }
              }}
        />
    </Page>
}