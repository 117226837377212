import {useEffect, useState} from "react";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import {useLayout} from "../contexts/LayoutContext";
import {Page} from "pages/Page";

export default function ActivateAccountPage() {
    const {profileWasFetched, user} = useLayout()
    const requester = useRequester()
    const translate = useTranslator()
    const [wasActivated, setWasActivated] = useState(null)

    useEffect(() => {
        const url = new URL(window.location.href)
        const token = url.searchParams.get('token')
        requester.request('POST', 'rpc/activate-user', {token}, response => {
            setWasActivated(true)
        }, error => {
            setWasActivated(false)
        })
    }, [])

    const renderContent = () => {
        if (!profileWasFetched) {
            return
        }
        if (profileWasFetched && user) {
            return <p>{translate('account.already-logged-in')}</p>
        }
        if (wasActivated === false) {
            return <p>{translate('account.activation.failed')}</p>
        }
        if (wasActivated) {
            return <p>{translate('account.activation.success')}</p>
        }
        return <p>{translate('status.verification.in-progress')}</p>
    }

    return <Page title={translate('account.activation')}>
        {renderContent()}
    </Page>
}