import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import englishTranslations from "translations/en.json";

i18n.use(initReactI18next)
    .init({
        resources: {
            en: {translation: englishTranslations}
        },
        lng: 'en',
    })