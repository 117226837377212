import {useState} from "react";
import {Form} from "@kof/react-commons"
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import {Page} from "pages/Page";

export default function RegistrationPage() {
    const requester = useRequester()
    const translate = useTranslator()
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [formValues, setFormValues] = useState({
        email: '',
    })

    return <Page title={translate('account.forgot-password')}>
        {!isSubmitted &&
            <Form values={formValues} onChange={(newValues) => setFormValues(newValues)}
                  elements={[
                      {type: 'text', label: translate('users.email'), name: 'email', isRequired: true},
                  ]}
                  submit={{
                      label: translate('form.submit'),
                      onSubmit: () => {
                          const data = {
                              email: formValues.email,
                          };
                          requester.request('POST', 'rpc/request-password-reset', data, response => {
                              setIsSubmitted(true)
                          })
                      }
                  }}
            />
        }
        {isSubmitted && <p>{translate('account.forgot-password.reset-requested')}</p>}
    </Page>
}