import {useEffect, useState} from "react";
import {Form} from "@kof/react-commons";
import {useLayout} from "contexts/LayoutContext";
import {useRequester} from "common/useRequester";
import useTranslator from "common/useTranslator";
import useMemberTypeTranslations from "common/useMemberTypeTranslations";
import useSalutationOptions from "user/useSalutationOptions";
import {useCountryOptions} from "common/useCountryOptions";
import {Page} from "pages/Page";
import "./ProfilePage.scss";

export default function ProfilePage() {
    const {user, setUser} = useLayout()
    const requester = useRequester()
    const translate = useTranslator()
    const salutationOptions = useSalutationOptions()
    const memberTypeTranslations = useMemberTypeTranslations()
    const countryOptions = useCountryOptions()
    const [formValues, setFormValues] = useState(null)

    useEffect(() => {
        if (user) {
            setFormValues({
                firstName: user.firstName,
                lastName: user.lastName,
                salutation: user.salutation,
                title: user.title || '',
                institutionCompany: user.institutionCompany || '',
                street1: user.street1,
                street2: user.street2 || '',
                city: user.city,
                zip: user.zip,
                country: user.country,
                email: user.email,
                memberType: user.memberType ? memberTypeTranslations[user.memberType] : '',
            })
        }
    }, [user])

    const createFormElements = () => {
        const elements = []
        elements.push({label: translate('users.memberType'), name: 'memberType', type: 'component', component: formValues.memberType})
        elements.push({label: translate('users.firstName'), name: 'firstName', type: 'text', disabled: true})
        elements.push({label: translate('users.lastName'), name: 'lastName', type: 'text', disabled: true})
        elements.push({
            label: translate('users.salutation'),
            name: 'salutation',
            type: 'dropdown',
            options: {items: salutationOptions}
        })
        elements.push({type: 'text', label: translate('users.title'), name: 'title'})
        elements.push({
            type: 'text',
            label: translate('users.institutionCompany'),
            name: 'institutionCompany',
            isRequired: (['corporate', 'corporateMainContact'].includes(user.memberType))
        })
        elements.push({type: 'text', label: translate('users.street1'), name: 'street1', isRequired: true})
        elements.push({type: 'text', label: translate('users.street2'), name: 'street2'})
        elements.push({type: 'text', label: translate('users.city'), name: 'city', isRequired: true})
        elements.push({type: 'text', label: translate('users.zip'), name: 'zip', isRequired: true})
        elements.push({
            type: 'dropdown',
            label: translate('users.country'),
            name: 'country',
            isRequired: true,
            options: {items: countryOptions}
        })
        elements.push({label: translate('users.email'), name: 'email', type: 'text', disabled: true})
        return elements
    }

    return <Page title={translate('profile')} className={'profile'}>
        {formValues && <Form
            values={formValues} onChange={(newValues) => setFormValues(newValues)}
            elements={createFormElements()}
            submit={{
                label: translate('form.submit'),
                onSubmit: () => {
                    const data = {
                        salutation: formValues.salutation,
                        title: formValues.title,
                        institutionCompany: formValues.institutionCompany,
                        street1: formValues.street1,
                        street2: formValues.street2,
                        city: formValues.city,
                        zip: formValues.zip,
                        country: formValues.country,
                    }
                    requester.request('PATCH', `rest/user/${user.id}`, data, () => {
                        requester.request('GET', `rpc/get-my-profile`, null, responseUser => {
                            setUser(responseUser)
                        })
                    })
                }
            }}
        />}
    </Page>
}